import React from 'react';
import { graphql } from 'gatsby';
import { IoIosArrowBack as ArrowBack } from 'react-icons/io';
import styled from '@emotion/styled';

import { SEO, Spacing, Text } from '../components/common';
import { Training } from '../@types/types';
import Article from '../components/Article';
import ContactInfoSection from '../components/ContactInfoSection';

type Props = {
  data: { training: Training };
  location: Location;
};

const TrainingPage = ({ data, location }: Props) => {
  const {
    training: {
      Title,
      Date,
      Content,
      Preview,
      ContactText,
      ContactTitle,
      contactEmployees,
      SignUpLink,
    },
  } = data;

  const seoDescription = Preview.substring(0, 270) || '';

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={Title}
        description={seoDescription}
      />
      <BackContainer>
        <BackButton href={`/koulutukset/`}>
          <ArrowBack />
          <Spacing axis="x" amount="default" />
          <Text variant="title-4">KOULUTUKSET</Text>
        </BackButton>
      </BackContainer>
      <Spacing axis="y" amount="large" />
      <Article
        title={Title}
        content={Content}
        date={Date}
        signUp={SignUpLink}
      />
      <ContactInfoSection
        contactTitle={ContactTitle}
        contactContent={ContactText}
        contactEmployees={contactEmployees?.slice(0, 2)}
      />
    </>
  );
};

export default TrainingPage;

export const query = graphql`
  query TrainingQuery($slug: String!) {
    training: strapiTrainings(slug: { eq: $slug }) {
      ...strapiTrainingsFragment
    }
  }
`;

const BackContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${(props) => props.theme.spacing.xxlarge};
`;

const BackButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;
